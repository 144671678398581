import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from 'axios';

import { renderAnimatedHeading } from "../../utils/animations";

class Block11 extends Component {
  static propTypes = {
    lang: PropTypes.string,
    isDisplayingSubmittedModal: PropTypes.bool,
    setIsDisplayingSubmittedModal: PropTypes.func,
    blockText: PropTypes.shape({
      __html: PropTypes.string
    }),
    heading: PropTypes.string,
    lead: PropTypes.shape({
      __html: PropTypes.string
    }),
    form: PropTypes.shape({
      action: PropTypes.string,
      method: PropTypes.string,
      inputs: PropTypes.arrayOf({
        label: PropTypes.string,
        type: PropTypes.string,
        name: PropTypes.string,
        placeholder: PropTypes.string
      }),
      buttonText: PropTypes.string
    })
  };

  formRef = React.createRef();

  state = {
    headingParsed: null,
  };

  componentDidMount() {
    const {
      data: { heading }
    } = this.props;

    this.setState({
      headingParsed: renderAnimatedHeading(heading)
    });
  }

  onSubmitClick(e) {
    const emailInput = this.props.data.form.inputs.find(e=>e.name==='email');
    const fnameInput = this.props.data.form.inputs.find(e=>e.name==='first_name');
    const lnameInput = this.props.data.form.inputs.find(e=>e.name==='last_name');
    const companyInput = this.props.data.form.inputs.find(e=>e.name==='company');

    const validation = [emailInput, fnameInput, lnameInput, companyInput].every(input => input.ref.current.checkValidity());
    if (validation) {
      e.preventDefault();
      const lang = this.props.lang;

      const reqBody = {
        email: emailInput.ref.current.value,
        first_name: fnameInput.ref.current.value,
        last_name: lnameInput.ref.current.value,
        company: companyInput.ref.current.value,
        lang
      };

      axios.post('https://api.theairr.com/api/demo', reqBody)
        .then(()=>{
          console.log('MailChimp integration commenced');

          window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
            'event': 'getDemo',
            'formType': 'getDemo',
            'formPosition': 'Footer',
            'referrer': document.referrer || 'direct'
          });

          [emailInput, fnameInput, lnameInput, companyInput].forEach(input => {
            input.ref.current.value = ''
          });
          this.props.setIsDisplayingSubmittedModal(true);
        })
        .catch(e=>{
          window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
            'event': 'formSubmissionFailure',
            'formType': 'getDemo',
            'formPosition': 'Footer',
            'referrer': document.referrer || 'direct',
            ...reqBody
          });
          console.log(e);
        });
    }
  }

  render() {
    const {
      data: { blockText, heading, lead, form, hint },
      lang
    } = this.props;

    const appURL = process.env.REACT_APP_URL;
    const returnUrl = `${appURL}/${lang}/submitted`;

    return (
      <section className="request-section">
        <div className="container">
          <div className="request-intro">
            <p dangerouslySetInnerHTML={blockText} />
            {(lang === 'en' || lang === "ru") &&
              <>
                <span className="hint">
                  <img src={hint} alt="" />
                </span>
              </>
            }
          </div>
          <div className='request-section-hidden' id="contacts">
            <h2 className="section-title">{heading}</h2>
            <p className="sub-text" dangerouslySetInnerHTML={lead} />
            <div className="form-wrap">
              <form className="form" action={form.action} method={form.method} ref={this.formRef}>
                <input type="hidden" name="oid" value={form.oid} />
                <input type="hidden" name="lead_source" value={form.leadSource} />
                <input type="hidden" name="retURL" value={returnUrl} />
                <div className="form__wrap">
                  {form.inputs.map(input => (
                    <div key={input.label}>
                      <label className="form__label">{input.label}</label>
                      <input
                        className="form__input"
                        type={input.type}
                        name={input.name}
                        placeholder={input.placeholder}
                        ref={input.ref}
                        required
                        onInvalid={(e) => e.target.setCustomValidity(form.errorMessage)}
                        onInput={(e) => e.target.setCustomValidity('')}
                      />
                    </div>
                  ))}
                  <div style={{ textAlign: 'center' }}>
                    <button className="form__btn" type="submit" onClick={this.onSubmitClick.bind(this)}>
                      {form.buttonText}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Block11;

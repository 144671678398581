import { connectRouter, routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';

import createRootReducer from './rootReducer';

const configureStore = (
  initialState,
  history,
  reducers = {},
  additionalMiddlewares = [],
  composeEnhancers = compose,
) => {
  const middlewares = [ ...additionalMiddlewares, routerMiddleware(history) ];
  const enhancers = [ applyMiddleware(...middlewares) ];

  const store = createStore(
    createRootReducer({ router: connectRouter(history), ...reducers }),
    initialState,
    composeEnhancers(...enhancers),
  );
  
  store.injectedReducers = {};

  return store;
};

export default configureStore;

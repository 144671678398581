import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3000";

const TermsOfUse = props => {
  const { opened, onClose, lang } = props;

  const classes = cn({
    modal: true,
    "js-login-modal": true,
    active: opened
  });

  const [page, setPage] = useState(null);

  useEffect(() => {
    axios.get(`${apiUrl}/api/pages/2?lang=${lang}`)
      .then(response => {
        setPage(response.data.body)
      });
  }, [lang, setPage]);

  return (
    <div className={classes}>
      <a className="modal-logo" href="/">
        <img src="images/modal-logo.svg" alt="" />
      </a>
      <span className="close-modal" onClick={onClose} role="presentation" />
      <div className="modal-page-content">
      <div dangerouslySetInnerHTML={{ __html: page }}></div>
      </div>
    </div>
  );
};

TermsOfUse.propTypes = {
  opened: PropTypes.bool,
  lang: PropTypes.string
};

TermsOfUse.defaultProps = {
  opened: false,
  lang: 'en'
};

export default TermsOfUse;

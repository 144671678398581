import React from 'react';
import { Router, Route, withRouter } from "react-router-dom"
import 'react-toastify/dist/ReactToastify.css';
import LocalStorageLangResolver from "./LocalStorageLangResolver"
import history from '../../utils/history';

const LocalizedRouter = props => {
  return (<Router history={history}>
    <Route path="/:routerLang/:subpage" component={LocalStorageLangResolver} exact={true} />
    <Route path="/:routerLang" component={LocalStorageLangResolver} exact={true} />
    <Route path="/" component={LocalStorageLangResolver} exact={true} />
  </Router>)
};

export default withRouter(LocalizedRouter);

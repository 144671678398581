import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import history from '../../utils/history';
import Root from './Root';
import { setLandingData, toggleMobileMenu } from "./actions"

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3000'

const CmsDataContainer = props => {
  const {
    subpage,
    routerLang,
    cmsData,
    menuOpen,
    dispatch,
  } = props;

  useEffect(() => {
    const prefLang = routerLang;

    if(!prefLang || !cmsData || !cmsData[prefLang]) {

      const langCheck = prefLang && prefLang !== undefined && prefLang !== 'undefined';

      const cmsQueryLang = langCheck ? prefLang : null;

      const url = cmsQueryLang ? `${apiUrl}/api/landing?lang=${cmsQueryLang}` : `${apiUrl}/api/landing`

      //console.log(`requesting CMS at ${url}, router lang: ${routerLang}`);

      axios.get(url)
        .then(response => {
          dispatch(setLandingData(response.data));

          if (!routerLang) {
            //console.log(`redirecting by CMS response to ${response.data.lang}`);
            history.push(`/${response.data.lang}`);
          }
        });
    }
  }, [routerLang, dispatch, cmsData]);

  const handlerProps = {
    onCloseModals: e => {
      e.preventDefault();
      history.push(`/${routerLang}`);
    },
    openMobileMenu: e => {
      dispatch(toggleMobileMenu());
    },
    openPrivacyModal: e => {
      e.preventDefault();
      history.push(`/${routerLang}/privacy`);
    },
    openTermsModal: e => {
      e.preventDefault();
      history.push(`/${routerLang}/terms`);
    },
  }

  const modalPredicateProps = {
    submittedOpen: subpage === 'submitted',
    mobileOpen: menuOpen,
    termsOpen: subpage === 'terms',
    privacyOpen: subpage === 'privacy',
    howDeleteUserDataOpen: subpage === 'how_delete_user_data'
  }

  if (cmsData && cmsData[routerLang]) {
    return <Root lang={routerLang} {...handlerProps} {...modalPredicateProps} data={cmsData[routerLang]} />
  } else {
    return null;
  }
};

const reduxPropsGetter = (state) => {
  const { cmsLang, cmsData, menuOpen } = state.root;

  return {
    cmsLang,
    cmsData,
    menuOpen
  }
}

CmsDataContainer.propTypes = {
  subpage: PropTypes.string,
  dispatch: PropTypes.func,
  cmsLang: PropTypes.string,
  cmsData: PropTypes.object,
  routerLang: PropTypes.string,
};

export default connect(reduxPropsGetter, null)(CmsDataContainer);

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-scroll';

const appURL = process.env.REACT_APP_URL.split('//');

const Block1 = props => {
  const {
    data: {
      heading,
      lead,
      button,
      videoButton,
      learnMoreButton
    },
    lang
  } = props;

  const signUpURL = `${appURL[0]}//app.${appURL[1]}/register?lang=${lang}`;

  return (
    <section className='intro-section'>
      <div className='container'>
        <h1 className='main-title'>
          { heading }
        </h1>
        <p className='main-text'>
          { lead }
        </p>
        <div className="main-link__market">
          <a href="https://play.google.com/store/apps/details?id=com.airr" target="_blank" rel="noopener noreferrer" className="main-link__market-item" >
            <img src='/images/google-play-badge-white.svg' alt="" />
          </a>
          <a href="https://apps.apple.com/cy/app/airr/id1520978628" target="_blank" rel="noopener noreferrer" className="main-link__market-item">
            <img src='/images/app-store-white.svg' alt="" />
          </a>
        </div>
        <div className='main-link-container'>
          <a
            className='main-link anchor'
            href={ signUpURL }
            duration={ 500 }
          >
            { button.text }
          </a>
          <Link
            className='main-link secondary'
            to='contacts'
            smooth={ true }
            duration={ 500 }
          >
            { learnMoreButton }
          </Link>
        </div>
        <a className='video-link mob' href={ videoButton.link }>
          { videoButton.text }
        </a>
      </div>
    </section>
  )
};

Block1.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string,
    lead: PropTypes.string,
    button: PropTypes.shape({
      text: PropTypes.string
    }),
    videoButton: PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.string
    })
  })
};

Block1.defaultProps = {
  data: {}
};

export default Block1;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';


class Tool extends Component {
  static propTypes = {
    name: PropTypes.string,
    text: PropTypes.string,
    closeText: PropTypes.string
  };

  static defaultProps = {
    name: '',
    text: '',
    closeText: ''
  };

  state = {
    opened: false
  };

  handleOpen = () => {
    this.setState({
      opened: true
    })
  };

  handleClose = e => {
    e.stopPropagation();
    this.setState({
      opened: false
    })
  };

  render() {
    const {
      name,
      text,
      closeText
    } = this.props;
    const { opened } = this.state;

    const classes = cn({
      tool: true,
      active: opened
    });

    return (
      <div
        className={ classes }
        onClick={ this.handleOpen }
        role='presentation'
      >
        <h3 className='tool__name'>
          { name }
        </h3>
        <span
          className='tool__close'
          onClick={ this.handleClose }
          role='presentation'
        >
          { closeText }
        </span>
        <p className='tool__text'>
          { text }
        </p>
      </div>
    )
  }
}

export default Tool;

import { createSelector } from 'reselect';


export const scroller = state => state.scroller;

export const fullPosition = createSelector(
  scroller,
  scroller => scroller.position
);

export const topPosition = createSelector(
  scroller,
  scroller => scroller.position.top
);

export const bottomPosition = createSelector(
  scroller,
  scroller => scroller.position.bottom
);

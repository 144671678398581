import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { renderAnimatedHeading } from '../../utils/animations';
import { checkElementVisibility } from '../../utils/visibility';


class Block6 extends Component {
  static propTypes = {
    data: PropTypes.shape({
      heading: PropTypes.string,
      lead: PropTypes.string,
      list1: PropTypes.arrayOf(PropTypes.string),
      list2: PropTypes.arrayOf(PropTypes.string),
      img1: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string
      }),
      img2: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string
      })
    })
  };

  ref = React.createRef();

  state = {
    headingParsed: null,
    visible: false
  };

  componentDidMount() {
    const { data: { heading } } = this.props;

    this.setState({
      headingParsed: renderAnimatedHeading(heading)
    })
  };

  async componentDidUpdate() {
    if (this.ref.current) {
      const visible = await checkElementVisibility(this.ref.current, -50);

      this.setState({
        visible
      });
    }
  }

  render() {
    const {
      data: {
        heading,
        lead,
        list1,
        list2,
        img1,
        img2
      }
    } = this.props;

    const { visible, headingParsed } = this.state;
  
    const blockClasses = cn({
      'schedule-section': true,
      'bg-before': true,
      'reporting-section': true,
      'block-hidden': true,
      'block-anim': visible
    });

    const classes = cn({
      'section-title': true,
      anim: visible
    });

    return (
      <section className={ blockClasses }>
        <img className='abs-img' style={{ display: 'none' }} src={ img1.src } alt={ img1.alt } />
        <div className='container'>
          <div className='img-wrap'>
            <img src={ img2.src } alt={ img2.alt } />
          </div>
          <div className='info-wrap'>
            <h2 className={ classes } ref={ this.ref }>
              { headingParsed || heading }
            </h2>
            <p className='sub-text' style={{ maxWidth: 445 }}>
              { lead }
            </p>
            <ul className='list'>
              { list1.map(item => (
                <li key={ item } className='list__item'>
                  { item }
                </li>
              )) }
            </ul>
            <ul className='list list_font'>
              { list2.map(item => (
                <li key={ item } className='list__item'>
                  { item }
                </li>
              )) }
            </ul>
          </div>
        </div>
      </section>
    )
  }
}

export default Block6;

import React, { Component } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import { renderAnimatedHeading } from "../../utils/animations";
import { checkElementVisibility } from "../../utils/visibility";
import Parallax from "parallax-js";

class Block10 extends Component {
  static propTypes = {
    data: PropTypes.shape({
      heading: PropTypes.string,
      blocks: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          link: PropTypes.string,
          img: PropTypes.shape({
            alt: PropTypes.string
          })
        })
      )
    })
  };

  ref = React.createRef();

  state = {
    headingParsed: null,
    visible: false,
    windowWidth: window.innerWidth
  };

  parallax = null;

  initParallax() {
    if (window.innerWidth > 1000) {
      const scene = document.getElementById("scene");

      this.parallax = new Parallax(scene);

      for(let i = 0; i < this.parallax.layers.length; i++) {
        let leyer = this.parallax.layers[i]
        leyer.style.position = 'relative';
      }
    }
  }

  enableScene() {
    if (this.parallax) {
      this.parallax.enable();
    }
  }

  disableScene() {
    if (this.parallax) {
      this.parallax.disable();
    }
  }

  handleScene() {
    this.setState({
      windowWidth: window.innerWidth
    });

    if (window.innerWidth <= 1000) {
      this.disableScene();
    } else {
      this.enableScene();
    }
  }

  componentDidMount() {
    const {
      data: { heading }
    } = this.props;

    this.setState({
      headingParsed: renderAnimatedHeading(heading)
    });

    this.handleScene();

    this.initParallax();

    window.addEventListener("resize", this.handleScene.bind(this));
  }

  async componentDidUpdate() {
    if (this.ref.current) {
      const visible = await checkElementVisibility(this.ref.current, 0);

      this.setState({
        visible
      });
    }
  }

  componentWillUnmount() {
    this.disableScene();

    window.removeEventListener("resize", this.handleScene.bind(this));
  }

  render() {
    const {
      data: { heading, blocks }
    } = this.props;

    const firstLine = blocks.filter(item => [2, 3, 4, 5, 6, 7, 8].includes(item.id));
    const secondLine = blocks.filter(item => [14, 9, 10, 1, 11, 12, 13].includes(item.id));
    const thirdLine = blocks.filter(item => [20, 15, 16, 17, 18, 19, 21].includes(item.id));

    const { visible, headingParsed, windowWidth } = this.state;

    const blockClasses = cn({
      "integrate-section": true,
      "block-hidden": true,
      "block-anim": visible
    });

    const classesBlock = cn({
      "integrate-wrap": true,
      mov: true,
      anim: visible
    });

    const classesHeading = cn({
      "section-title_white": true,
      "section-title": true,
      anim: visible
    });

    if (windowWidth <= 1000) {
      return (
        <section className={blockClasses} id="integration">
          <div className="container">
            <h2 className={classesHeading} ref={this.ref}>
              {headingParsed || heading}
            </h2>
            <div className={classesBlock}>
              {blocks.map(item => (
                <div key={item.id} className={`integrate ${visible ? 'integrate-first__line' : ''} ${item.id === 1 ? "active" : ""}`}>
                  <img src={item.img.src} alt={item.img.alt} style={item.id > 1 && item.id < 10 ? {opacity: '0.5'} : null}/>
                </div>
              ))}
            </div>
          </div>
        </section>
      );
    }

    return (
      <section className={blockClasses} id="integration">
        <div className="container">
          <h2 className={classesHeading} ref={this.ref}>
            {headingParsed || heading}
          </h2>
          <div style={{ minHeight: '300px' }} data-hover-only="true" id="scene">
            <div data-depth={`0.4`}>
              <div className={classesBlock}>
                {firstLine.map(item => (
                  <div key={item.id} className={`integrate ${visible ? 'integrate-first__line' : ''}`}>
                    <img src={item.img.src} alt={item.img.alt} style={{opacity: '0.5'}}/>
                  </div>
                ))}
              </div>
            </div>
            <div data-depth={`0.3`}>
              <div className={classesBlock}>
                {secondLine.map(item => (
                  <div key={item.id} className={`integrate ${visible ? 'integrate-second__line' : ''} ${item.id === 1 ? "active" : ""}`} style={item.id < 12 ? {order: 0} : {order: 2}}>
                    <img src={item.img.src} alt={item.img.alt} style={item.id === 9 ? {opacity: '0.5'} : null}/>
                  </div>
                ))}
              </div>
            </div>
            <div data-depth={`0.2`}>
              <div className={classesBlock}>
                {thirdLine.map(item => (
                  <div key={item.id} className={`integrate ${visible ? 'integrate-third__line' : ''}`}>
                    <img src={item.img.src} alt={item.img.alt}/>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Block10;

export const checkElementVisibility = (el, animationOffset) => {
  return new Promise(resolve => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // const height = document.documentElement.clientHeight;
        const bounding = entry.boundingClientRect;

        if (
          bounding.top >= 0 &&
          bounding.left >= 0 &&
          bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
        ) {
          resolve(true);
        }
        // if ((height - top) > animationOffset) {
        //   resolve(true);
        // }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 1.0
      }
    );

    observer.observe(el);
  });
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { renderAnimatedHeading } from '../../utils/animations';
import { checkElementVisibility } from '../../utils/visibility';


class Block9 extends Component {
  static propTypes = {
    data: PropTypes.shape({
      heading: PropTypes.string,
      lead: PropTypes.string,
      list: PropTypes.arrayOf(PropTypes.string),
      img: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string
      })
    })
  };

  ref = React.createRef();

  state = {
    headingParsed: null,
    visible: false
  };

  componentDidMount() {
    const { data: { heading } } = this.props;

    this.setState({
      headingParsed: renderAnimatedHeading(heading)
    })
  };

  async componentDidUpdate() {
    if (this.ref.current) {
      const visible = await checkElementVisibility(this.ref.current, -50);

      this.setState({
        visible
      });
    }
  }

  render() {
    const {
      data: {
        heading,
        lead,
        list,
        img
      }
    } = this.props;

    const { visible, headingParsed } = this.state;

    const blockClasses = cn({
      'schedule-section': true,
      'onboarding-section': true,
      'block-hidden': true,
      'block-anim': visible
    });

    const classes = cn({
      'section-title': true,
      anim: visible
    });

    return (
      <section className={ blockClasses }>
        <div className='container'>
          <div className='info-wrap'>
            <h2 className={ classes } ref={ this.ref }>
              { headingParsed || heading }
            </h2>
            <p className='sub-text'>
              { lead }
            </p>
            <ul className='list'>
              { list.filter(v => v).map(item => (
                <li key={ item } className='list__item'>
                  { item }
                </li>
              )) }
            </ul>
          </div>
          <div className='img-wrap'>
            <img src={ img.src } alt={ img.alt } />
          </div>
        </div>
      </section>
    )
  }
}

export default Block9;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-scroll';
import cn from 'classnames';

import { renderAnimatedHeading } from '../../utils/animations';
import { checkElementVisibility } from '../../utils/visibility';

const appURL = process.env.REACT_APP_URL.split('//');

class Block12 extends Component {
  static propTypes = {
    lang: PropTypes.string,
    data: PropTypes.shape({
      heading: PropTypes.shape({
        __html: PropTypes.string
      }),
      blocks: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          price: PropTypes.string,
          priceText: PropTypes.string,
          text: PropTypes.string,
          link: PropTypes.string
        })
      )
    })
  };

  ref = React.createRef();

  state = {
    headingParsed: null,
    visible: false
  };

  componentDidMount() {
    const { data: { heading } } = this.props;

    this.setState({
      headingParsed: renderAnimatedHeading(heading.__html)
    })
  };

  async componentDidUpdate() {
    if (this.ref.current) {
      const visible = await checkElementVisibility(this.ref.current, -50);

      this.setState({
        visible
      });
    }
  }

  render() {
    const {
      lang,
      data: {
        heading,
        blocks
      }
    } = this.props;

    const { visible } = this.state;

    const blockClasses = cn(
      'block-hidden',
      {'block-anim': visible}
    );

    const signUpURL = `${appURL[0]}//app.${appURL[1]}/register?lang=${lang}`;

    return (
      <section className={ blockClasses } id='pricing'>
        <div className='pricing container'>
          <h2 ref={ this.ref } className="pricing-header section-title anim" dangerouslySetInnerHTML={ heading } />
          <div className="pricing-block__wrapper">
            { blocks.map((item, index, array) => (

              <div key={ item.name } className="pricing-block__item">
                <div className="pricing-block__header">
                  <p className="pricing-block__name">
                    {item.name}
                  </p>
                  <p className="pricing-block__price">
                    {parseInt(item.price) ? `$\u00A0${item.price}` : item.price}
                  </p>
                  <span className="pricing-block__price-text">
                    {item.priceText ? item.priceText : <span style={{opacity: 0}}>Blank text</span>}
                  </span>
                </div>
                <div className="pricing-block__text-and-link-container">
                  <div className="pricing-block__text-wrapper">
                    <p className="pricing-block__text">
                      {item.text}
                    </p>
                  </div>
                  <div className="pricing-block__link-wrapper">
                    {lang === 'en' && (
                      <>
                        {(index !== 0 && index !== array.length - 1) ? <img src='/images/block12-link.svg' alt="" /> : <div style={{minHeight: '19px'}} />}
                      </>
                    )}
                    {index !== array.length - 1 ? (
                      <>
                        <a href={ signUpURL } className='pricing-block__link'>
                          { item.link }
                        </a>
                      </>
                    ) : (
                      <Link
                        className='pricing-block__link'
                        to='contacts'
                        duration={ 500 }
                        smooth
                      >
                        { item.link }
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            )) }
          </div>
        </div>
      </section>
    )
  }
}

export default Block12;

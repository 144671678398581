import React from 'react';
import PropTypes from 'prop-types';


const Block2 = props => {
  const { data: { videoSrc } } = props;

  return (
    <section className='video-section' id='scroll'>
      <div className='video-wrap'>
        <video src={ videoSrc } autoPlay='autoplay' muted='muted' controls />
      </div>
    </section>
  )
};

Block2.propTypes = {
  data: PropTypes.shape({
    videoSrc: PropTypes.string
  })
};

Block2.defaultProps = {
  data: {}
};

export default Block2;

import produce from 'immer';

import * as types from './constants';


const initialState = {
  cmsData: {},
  cmsLang: '',
  menuOpen: false,
};

const rootReducer = (state = initialState, action) =>
  produce(state, draft => {
    const { type, payload } = action;

    switch (type) {

      case types.SET_LANDING_DATA: {
        draft.cmsLang = payload.lang;
        draft.cmsData[payload.lang] = payload.data;

        break;
      }

      case types.TOGGLE_MOBILE: {
        draft.menuOpen = !state.menuOpen;

        break;
      }

      default: {
        return state
      }
    }
  });

export default rootReducer;

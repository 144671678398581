import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';


const Login = props => {
  const {
    data: {
      img,
      heading,
      form,
      fields,
      submitButton,
      registerHeading,
      registerText,
      registerButton
    },
    opened,
    onRegisterOpen,
    onClose
  } = props;

  const classes = cn({
    modal: true,
    'js-login-modal': true,
    active: opened
  });

  return (
    <div className={ classes }>
      <a className='modal-logo' href='/'>
        <img src={ img.src } alt={ img.alt } />
      </a>
      <span
        className='close-modal'
        onClick={ onClose }
        role='presentation'
      />
      <div className='modal-content'>
        <form
          className='modalForm'
          action={ form.action }
          method={ form.method }
        >
          <div className='modalForm__wrap'>
            <span className='modalForm__title'>
              { heading }
            </span>
            <p className='modalForm__info modalForm__info_margin'>
              { form.label }
            </p>
            { fields.map(item => (
              <div key={ item.label }>
                <label className='modalForm__label'>
                  { item.label }
                </label>
                { item.additionalLink && (
                  <a className='modalForm__password' href={ item.additionalLink.link }>
                    { item.additionalLink.text }
                  </a>
                ) }
                <input
                  className='modalForm__input'
                  type={ item.type }
                  name={ item.name }
                  placeholder={ item.placeholder }
                />
              </div>
            )) }
            <button className='modalForm__btn' type={ submitButton.type }>
              { submitButton.text }
            </button>
          </div>
          <div className='modalForm__footer'>
            <p className='modalForm__info'>
              { registerText }
              <a
                className='modalForm__link js-register'
                href={ registerButton.link }
                onClick={ onRegisterOpen }
              >
                { registerButton.text }
              </a>
            </p>
          </div>
        </form>
        <div className='register-wrap'>
          <span>{ registerHeading }</span>
          <p>
            { registerText }
          </p>
          <a
            className='js-register'
            href={ registerButton.link }
            onClick={ onRegisterOpen }
          >
            { registerButton.text }
          </a>
        </div>
      </div>
    </div>
  )
};

Login.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string,
    img: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string
    }),
    form: PropTypes.shape({
      action: PropTypes.string,
      method: PropTypes.string
    }),
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        type: PropTypes.string,
        name: PropTypes.string,
        placeholder: PropTypes.string,
        additionalLink: PropTypes.shape({
          link: PropTypes.string,
          text: PropTypes.string
        })
      })
    ),
    submitButton: PropTypes.shape({
      type: PropTypes.string,
      text: PropTypes.string
    }),
    registerHeading: PropTypes.string,
    registerText: PropTypes.string,
    registerButton: PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.string
    })
  }),
  opened: PropTypes.bool,
  onRegisterOpen: PropTypes.func
};

Login.defaultProps = {
  data: {},
  opened: false
};

export default Login;

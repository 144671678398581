import React from 'react';


const letterAnimationDelay = 0.05;

export const renderAnimatedHeading = string => {
  let transitionDelay = -letterAnimationDelay;

  const arrayByBr = string.split(' ');

  return arrayByBr.map((word, index) => {
    if (index !== arrayByBr.length - 1) {
      return (
        <span key={index} style={{ display: 'inline-block' }}>{ word }</span>
      )
    } else {
      return (
        <span key={index} className='animation-holder'>
          { word.split('').map((letter, index) => {
            transitionDelay = transitionDelay + letterAnimationDelay;
  
            return (
              <span key={index} className='overflow-hidden'>
                <span
                  className='transform-animate'
                  style={{
                    transitionDelay: `${transitionDelay}s`
                  }}
                >
                  { letter }
                </span>
            </span>
            )
          }) }
        </span>
      )
    }
  });
};

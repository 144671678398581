import Block1 from '../Block1';
import Block2 from '../Block2';
import Block3 from '../Block3';
import Block4 from '../Block4';
import Block5 from '../Block5';
import Block6 from '../Block6';
import Block7 from '../Block7';
import Block8 from '../Block8';
import Block9 from '../Block9';
import Block10 from '../Block10';
import Block11 from '../Block11';
import Block12 from '../Block12';


const Blocks = {
  Block1,
  Block2,
  Block3,
  Block4,
  Block5,
  Block6,
  Block7,
  Block8,
  Block9,
  Block10,
  Block11,
  Block12
};

export default Blocks;

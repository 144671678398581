import React from 'react';
import cn from "classnames";

const HowDeleteUserDataPage = (props) => {
  const { opened, onClose } = props;

  const classes = cn({
    modal: true,
    "js-login-modal": true,
    active: opened
  });

  return <div className={classes}>
    <a className="modal-logo" href="/">
      <img src="images/modal-logo.svg" alt="" />
    </a>
    <span className="close-modal" onClick={onClose} role="presentation" />
    <div className="modal-page-content" style={{ width: '100%' }}>
      <h2>How delete user account</h2>
      <br/>
      <div><b>App: </b>AIRR</div>
      <div><b>Developer: </b>Summer Advisors Ltd</div>
      <br/>
      <div><b>Steps:</b></div>
      <div>1. Contact us support@theairr.com using email registered in app for deleting account related with that email</div>
      <br/>
      <div>The following information will be deleted: profile picture, email, password, language, name, surname, phone number, linkedIn link, sessions history</div>
    </div>
  </div>;
}

export default HowDeleteUserDataPage;

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Footer = props => {
  const { data: { img, socials, rights, terms, privacy }, mobileOpen, onTermsOpen, onPrivacyOpen } = props;

  const navClasses = cn({
    nav: true,
    active: mobileOpen
  });

  const filteredSocials = socials.filter((item) => item && item.link && item.icon);

  return (
    <footer className='footer' hidden={ mobileOpen }>
      <div className='container desktop'>
        <a className='footer-logo' href='/'>
          <img src={ img.src } alt={ img.alt } />
        </a>
        <div className="footer-socials">
          { filteredSocials.map((item,idx) => (
            <a key= { `desktop_social_${idx}` } href={ item.link } target="_blank" rel="noopener noreferrer" className="footer-socials__item">
              <img src={ item.icon } alt=""/>
            </a>
          )) }
        </div>
        <div className="footer-market">
          <a href="https://play.google.com/store/apps/details?id=com.airr" target="_blank" rel="noopener noreferrer" >
            <img src='/images/google-play-badge.svg' alt="" />
          </a>
          <a href="https://apps.apple.com/cy/app/airr/id1520978628" target="_blank" rel="noopener noreferrer" className="footer-market__item">
            <img src='/images/app-store-badge.svg' alt="" />
          </a>
        </div>
        <div className="footer-rights">
          { rights }
        </div>
        <nav className="footer-nav__desktop">
          <ul className='nav__list'>
            <li className='nav__item'>
              <a className='nav__link' href='/' onClick={ onTermsOpen }>
              { terms }
              </a>
            </li>
            <li className='nav__item'>
              <a className='nav__link' href='/' onClick={ onPrivacyOpen }>
              { privacy }
              </a>
            </li>
          </ul>
        </nav>
        <a title="Realtime application protection" href="https://www.sqreen.com/?utm_source=badge" style={{marginLeft: '30px'}}>
          <img  src='/images/sqreen.svg' alt="Sqreen | Runtime Application Protection" />
        </a>
      </div>
      <div className="container mobile">
        <div className="footer-socials">
          { filteredSocials.map((item,idx) => (
            <a key= { `mobile_social_${idx}` } href={ item.link } target="_blank" rel="noopener noreferrer" className="footer-socials__item">
              <img src={ item.icon } alt=""/>
            </a>
          )) }
        </div>
        <div className="footer-market__mobile">
          <a href="https://play.google.com/store/apps/details?id=com.airr" target="_blank" rel="noopener noreferrer" >
            <img src='/images/google-play-badge.svg' alt="" />
          </a>
          <a href="https://apps.apple.com/cy/app/airr/id1520978628" target="_blank" rel="noopener noreferrer" className="footer-market__item">
            <img src='/images/app-store-badge.svg' alt="" />
          </a>
        </div>
        <div className="container between">
          <div className="footer-rights">
            { rights }
          </div>
          <nav className={ navClasses }>
            <ul className='nav__list'>
              <li className='nav__item'>
                <a className='nav__link' href='/' onClick={ onTermsOpen }>
                  { terms }
                </a>
              </li>
              <li className='nav__item'>
                <a className='nav__link' href='/' onClick={ onPrivacyOpen }>
                  { privacy }
                </a>
              </li>
            </ul>
          </nav>
          <a title="Realtime application protection" href="https://www.sqreen.com/?utm_source=badge" style={{marginLeft: '25px'}}>
            <img  src='/images/sqreen.svg' alt="Sqreen | Runtime Application Protection" />
          </a>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  data: PropTypes.shape({}),
  mobileOpen: PropTypes.bool,
  termsOpen: PropTypes.bool,
  onTermsOpen: PropTypes.func,
  onPrivacyOpen: PropTypes.func,
};

Footer.defaultProps = {
  data: {},
  mobileOpen: false,
  termsOpen: false,
  privacyOpen: false,
};

export default Footer;

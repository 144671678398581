import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';

import { createBrowserHistory as createHistory } from 'history';
import { ConnectedRouter } from 'connected-react-router';

import configureStore from './store/configureStore.env';
import initialState from './store/initialState';

import Root from './containers/Root';

import * as serviceWorker from './serviceWorker';

const gaTag = process.env.REACT_APP_GA_TAG || null;

if (gaTag) {
  ReactGA.initialize(process.env.REACT_APP_GA_TAG);
  ReactGA.pageview(window.location.pathname + window.location.search);
}

const history = createHistory({});

const store = configureStore(initialState, history);

const render = () => {
  hydrate(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Root/>
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
  );
};

render();
serviceWorker.unregister();

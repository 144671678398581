import React, { Fragment } from 'react';
import { Link } from 'react-scroll';

const ProductsDropdown = ({ product, products }) => {
  const onProductClick = (item) => {
    if (item.link) {
      window.open(item.link, '_blank');
    }
  }

  return (
    <li className='nav__item products-desktop'>
      <Link className='nav__link'>{product}</Link>
      <div className="dropdown products-dropdown sign-in">
        <div className="dropdown__content products-dropdown__content products-dropdown__content--desktop">
          {products.map(item => (
            <Fragment key={`${item.link}${item.to}${item.offset}`}>
              {item.link ? (
                <button key={item.key} className={`lang-dropdown__content-item`} onClick={() => onProductClick(item)}>
                  {item.text}
                </button>
              ) : (
                <Link
                  className='lang-dropdown__content-item'
                  style={{padding: '1px 6px'}}
                  to={item.to}
                  duration={500}
                  offset={Number(item.offset) || 0}
                  smooth
                >
                  {item.text}
                </Link>
              )}
            </Fragment>
          ))}
        </div>
        <span style={{marginTop: '10px', left: '45%'}}/>
      </div>
    </li>
  )
};

export default ProductsDropdown;

import produce from 'immer';

import { SCROLL_CHANGE } from './constants';


const initialState = {
  position: {}
};

const scrollerReducer = (state = initialState, action) =>
  produce(state, draft => {
    const { type, payload } = action;

    switch (type) {
      case SCROLL_CHANGE: {
        draft.position = payload;

        break;
      }

      default: {
        return state
      }
    }
  });

export default scrollerReducer;

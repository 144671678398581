import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'react-toastify/dist/ReactToastify.css';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Register from '../../components/Register';
import Login from '../../components/Login';
import Blocks from '../Blocks';
import TermsOfUse from '../../components/TermsOfUse';
import PrivacyPolicy from '../../components/PrivacyPolicy';
import SubmittedModal from '../../components/SubmittedModal';
import Cookies from '../../components/Cookies';
import {createStructuredSelector} from "reselect"
import {bottomPosition, topPosition} from "../../utils/scroller/selectors"
import {debounce} from "lodash"
import scrollChange from "../../utils/scroller/actions"
import {connect} from "react-redux"
import HowDeleteUserDataPage from "../../components/HowDeleteUserData/HowDeleteUserData";

const Root = props => {
  const {
    lang,
    data,
    mobileOpen,
    termsOpen,
    privacyOpen,
    howDeleteUserDataOpen,
    onCloseModals,
    openMobileMenu,
    openPrivacyModal,
    openTermsModal,
  } = props;

  const [isDisplayingSubmittedModal, setIsDisplayingSubmittedModal] = useState(false);


  if (data) {
    const refInputs = [];

    data.block11.form.inputs.forEach(e=>{
      const newEl = {
        ...e,
        ref: React.createRef()
      };

      refInputs.push(newEl);
    });

    const block11 = {
      ...data.block11,
      form: {
        ...data.block11.form,
        inputs: refInputs
      }
    };

    return (
      <div className='wrapper'>
        <Header
          lang={ lang }
          data={ data.header }
          mobileOpen={ mobileOpen }
          onMobileOpen={ openMobileMenu }
          onClose={ onCloseModals }
        />
        <main className='main'>
          <Blocks.Block1 data={ data.block1 } lang={ lang }/>
          <Blocks.Block2 data={ data.block2 } />
          <Blocks.Block3 data={ data.block3 } lang={ lang } />
          <Blocks.Block4 data={ data.block4 } />
          <Blocks.Block5 data={{ block: data.block5, button: data.block1.button }} lang={ lang } />
          <Blocks.Block6 data={ data.block6 } />
          <Blocks.Block7 data={ data.block7 } />
          <Blocks.Block8 data={ data.block8 } />
          <Blocks.Block9 data={ data.block9 } />
          <Blocks.Block12 lang={ lang } data={ data.block12 } />
          <Blocks.Block10 data={ data.block10 } />
          <Blocks.Block11 data={ block11 } lang={lang} isDisplayingSubmittedModal={isDisplayingSubmittedModal} setIsDisplayingSubmittedModal={setIsDisplayingSubmittedModal} />
        </main>
        <Footer
          data={ data.footer }
          mobileOpen={ mobileOpen }
          onTermsOpen={ openTermsModal }
          onPrivacyOpen={ openPrivacyModal }
        />
        <Register
          data={ data.register }
          onClose={ onCloseModals }
        />
        <Login
          data={ data.login }
          onClose={ onCloseModals }
        />
        <TermsOfUse
          lang={ lang }
          opened={ termsOpen }
          onClose={ onCloseModals }
        />
        <PrivacyPolicy
          lang={ lang }
          opened={ privacyOpen }
          onClose={ onCloseModals }
        />
        <HowDeleteUserDataPage opened={howDeleteUserDataOpen} onClose={onCloseModals} />
        <SubmittedModal
          opened={ isDisplayingSubmittedModal }
          successMessage={ data.block11.form.successMessage }
          onClose={ () => setIsDisplayingSubmittedModal(false) }
        />
        <Cookies
          onPrivacyOpen={ openPrivacyModal }
        />
      </div>
    );
  }

  return (<div></div>)
};

const mapStateToProps = createStructuredSelector({
  topPosition,
  bottomPosition
});

const mapDispatchToProps = (
  dispatch,
  ownProps,
  debounceDispatch = debounce(dispatch, 500)
)  => {
  return { dispatch, debounceDispatch }
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { debounceDispatch } = dispatchProps;

  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,

    scrollChange: payload => debounceDispatch(scrollChange(payload)),

    dispatch: undefined
  };
};

Root.propTypes = {
  scrollChange: PropTypes.func,
  topPosition: PropTypes.number,
  bottomPosition: PropTypes.number,

  submittedOpen: PropTypes.bool,
  mobileOpen: PropTypes.bool,
  termsOpen: PropTypes.bool,
  privacyOpen: PropTypes.bool,

  onCloseModals: PropTypes.func,
  openMobileMenu: PropTypes.func,
  openPrivacyModal: PropTypes.func,
  openTermsModal: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Root);

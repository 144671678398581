import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-scroll';
import cn from 'classnames';

import { renderAnimatedHeading } from '../../utils/animations';
import { checkElementVisibility } from '../../utils/visibility';


class Block5 extends Component {
  static propTypes = {
    data: PropTypes.shape({
      block: PropTypes.shape({
        heading: PropTypes.string,
        lead: PropTypes.string,
        list1: PropTypes.arrayOf(PropTypes.string),
        list2: PropTypes.arrayOf(PropTypes.string),
        img: PropTypes.shape({
          src: PropTypes.string,
          alt: PropTypes.string
        })
      }),
      button: PropTypes.shape({
        text: PropTypes.string
      })
    })
  };

  ref = React.createRef();

  state = {
    headingParsed: null,
    visible: false
  };

  componentDidMount() {
    const { data: { block: { heading } } } = this.props;

    this.setState({
      headingParsed: renderAnimatedHeading(heading)
    })
  };

  async componentDidUpdate() {
    if (this.ref.current) {
      const visible = await checkElementVisibility(this.ref.current, -50);

      this.setState({
        visible
      });
    }
  }

  render() {
    const {
      data: {
        block: {
          heading,
          lead,
          list1,
          // list2,
          img,
          hintDesktop,
          hintMobile
        },
        button
      },
      lang
    } = this.props;

    const { visible, headingParsed } = this.state;

    const blockClasses = cn({
      'schedule-section': true,
      'companies-section': true,
      'block-hidden': true,
      'block-anim': visible
    });

    const classes = cn({
      'section-title': true,
      anim: visible
    });

    return (
      <>
        <section className={ blockClasses } id="requestdemo">
          <Link
            className='main-link tertiary'
            to='contacts'
            smooth={ true }
            duration={ 500 }
          >
            { button.text }
          </Link>
        </section>
        <section className={ blockClasses } id='features'>
          <div className='container'>
            <div className='info-wrap'>
              <h2 className={ classes } ref={ this.ref }>
                { headingParsed || heading }
              </h2>
              <p className='sub-text'>
                { lead }
              </p>
              <ul className='list'>
                { list1.map(item => (
                  <li key={ item } className='list__item'>
                    { item }
                  </li>
                )) }
              </ul>
            </div>
            <div className='img-wrap'>
              <img src={ img.src } alt={ img.alt } />
              {/* <ul className='list list_font'>
                { list2.map(item => (
                  <li key={ item } className='list__item'>
                    { item }
                  </li>
                )) }
              </ul> */}
            </div>
            {(lang === 'en' || lang === 'ru') &&
              <>
                <span className="hint desktop">
                  <img src={hintDesktop} alt=""/>
                </span>
                <span className="hint mobile">
                  <img src={hintMobile} alt=""/>
                </span>
              </>
            }
          </div>
        </section>
      </>
    )
  }
}

export default Block5;

import React from 'react';
import PropTypes from 'prop-types';
import history from "../../utils/history"

const LangDropdown = props => {

  const { mobile, lang } = props;
  const langs = [
    { key: 'en', text: 'English', icon: 'english.svg' },
    { key: 'fr', text: 'Français', icon: 'french.png' },
    { key: 'ru', text: 'Русский', icon: 'russian.svg' },
    { key: 'zh', text: 'Chinese', icon: 'china.png' },
    { key: 'es', text: 'Spanish', icon: 'spain.png' },
  ];

  const changeLanguage = (language) => {
    history.push(`/${language}`);
  };

  let activeLanguage = langs.find(item => item.key === lang);

  if (!activeLanguage) {
    activeLanguage = langs[0];
  }

  return (
    <div
      className={`${ mobile ? 'mob' : 'tablet' } head-btn lang-dropdown`}
      href="#"
    >
      { mobile &&
        <>{activeLanguage.text}</>
      }
      <img src={`/images/lang/${activeLanguage.icon}`} alt={activeLanguage.text} />
      <div className="lang-dropdown__content">
        {langs.map(item => (
          <button key={item.key} onClick={() => changeLanguage(item.key)} className={`lang-dropdown__content-item${item.key === lang ? ' active' : ''}`}>
          <img src={`/images/lang/${item.icon}`} alt="" />
          {item.text}
          </button>
          ))}
      </div>
    </div>
  )
};

LangDropdown.propTypes = {
  mobile: PropTypes.bool,
  lang: PropTypes.string
};

LangDropdown.defaultProps = {
  mobile: false,
  lang: ''
};

export default LangDropdown;

import { combineReducers } from 'redux';

import rootReducer from '../containers/Root/reducer';
import scrollerReducer from '../utils/scroller/reducer';

const createRootReducer = injectedReducers => combineReducers({
  root: rootReducer,
  scroller: scrollerReducer,
  ...injectedReducers
});

export default createRootReducer;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { renderAnimatedHeading } from '../../utils/animations';
import { checkElementVisibility } from '../../utils/visibility';


class Block4 extends Component {
  static propTypes = {
    data: PropTypes.shape({
      heading: PropTypes.shape({
        __html: PropTypes.string
      }),
      leadBlock: PropTypes.shape({
        num: PropTypes.string,
        name: PropTypes.string,
        text: PropTypes.string
      }),
      blocks: PropTypes.arrayOf(
        PropTypes.shape({
          img: PropTypes.shape({
            src: PropTypes.string,
            alt: PropTypes.string
          }),
          num: PropTypes.string,
          name: PropTypes.string,
          text: PropTypes.string
        })
      )
    })
  };
  
  ref = React.createRef();
  
  state = {
    headingParsed: null,
    visible: false
  };
  
  componentDidMount() {
    const { data: { heading } } = this.props;
    
    this.setState({
      headingParsed: renderAnimatedHeading(heading.__html)
    })
  };
  
  async componentDidUpdate() {
    if (this.ref.current) {
      const visible = await checkElementVisibility(this.ref.current, -50);
      
      this.setState({
        visible
      });
    }
  }
  
  render() {
    const {
      data: {
        heading,
        leadBlock,
        blocks
      }
    } = this.props;
  
    const { visible } = this.state;
  
    const blockClasses = cn({
      'ubo-section': true,
      'block-hidden': true,
      'block-anim': visible
    });
  
    return (
      <section className={ blockClasses }>
        <div className='container'>
          <h2 className='section-title' ref={ this.ref } dangerouslySetInnerHTML={ heading } />
          <div className='ubo-head'>
            <span className='ubo-number'>{ leadBlock.num }</span>
            <h3 className='ubo-name'>{ leadBlock.name }</h3>
            <p className='ubo-text'>{ leadBlock.text }</p>
          </div>
          <div className='ubo-wrap'>
            { blocks.map(item => (
              <div key={ item.name } className='ubo'>
                <div className='ubo__img'></div>
                <span className='ubo__number'>
                { item.num }
              </span>
                <h3 className='ubo__name'>
                  { item.name }
                </h3>
                <p className='ubo__text'>
                  { item.text }
                </p>
              </div>
            )) }
          </div>
        </div>
      </section>
    )
  }
}

export default Block4;

import * as types from './constants';

export const setLandingData = cmsResponse => ({
  type: types.SET_LANDING_DATA,
  payload: {
    data: cmsResponse,
    lang: cmsResponse.lang
  }
});

export const toggleMobileMenu = () => ({
  type: types.TOGGLE_MOBILE,
  payload: {}
});

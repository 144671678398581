import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Tool from '../../components/Tool';

import { renderAnimatedHeading } from '../../utils/animations';
import { checkElementVisibility } from '../../utils/visibility';


class Block3 extends Component {
  static propTypes = {
    data: PropTypes.shape({
      heading: PropTypes.shape({
        __html: PropTypes.string
      }),
      closeText: PropTypes.string,
      blocks: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          text: PropTypes.string
        })
      )
    })
  };

  ref = React.createRef();

  state = {
    headingParsed: null,
    visible: false
  };

  componentDidMount() {
    const { data: { heading } } = this.props;

    this.setState({
      headingParsed: renderAnimatedHeading(heading.__html)
    })
  };

  async componentDidUpdate() {
    if (this.ref.current) {
      const visible = await checkElementVisibility(this.ref.current, -50);

      this.setState({
        visible
      });
    }
  }

  render() {
    const {
      data: {
        heading,
        closeText,
        blocks,
        hintDesktop,
        hintMobile
      },
      lang
    } = this.props;

    const { visible } = this.state;

    const blockClasses = cn({
      'tools-section': true,
      'block-hidden': true,
      'block-anim': visible
    });

    const classes = cn({
      'section-title': true,
      anim: visible
    });

    return (
      <section className={ blockClasses } id='about'>
        <div className='container'>
          <h2 className={ classes } ref={ this.ref } dangerouslySetInnerHTML={ heading } />
          <div className='tools-wrap scroll-wrap'>
            { blocks.map(item => (
              <Tool
                key={ item.name }
                name={ item.name }
                text={ item.text }
                closeText={ closeText }
              />
            )) }
          </div>
          {(lang === 'en' || lang === 'ru') &&
            <>
              <span className="hint desktop">
                <img src={hintDesktop} alt=""/>
              </span>
              <span className="hint mobile">
                <img src={hintMobile} alt=""/>
              </span>
            </>
          }
        </div>
      </section>
    )
  }
}

export default Block3;

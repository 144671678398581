import React, { Fragment } from 'react';
import { Link } from 'react-scroll';

const ProductsDropdownMobile = ({ product, products, onLinkClick }) => {
  const onProductClick = (item) => {
    if (item.link) {
      window.open(item.link, '_blank');
    }
  }

  return (
    <li className="nav__item mob">
      <div className="head-btn mob lang-dropdown products-dropdown--mobile">
        <div style={{display: 'flex'}}>
          {product}
          <div className={`chevron`}><img src="/images/icons/chevron.svg" alt="chevron" /></div>
        </div>
        <div className="lang-dropdown__content products-dropdown__content--mobile">
          {products.map(item => (
            <Fragment key={`${item.link}${item.to}${item.offset}`}>
              {item.link ? (
                <button key={item.key} className={`products-dropdown__content-item--mobile`} onClick={() => onProductClick(item)}>
                  {item.text}
                </button>
              ) : (
                <Link
                  className='products-dropdown__content-item--mobile'
                  style={{padding: '1px 6px'}}
                  to={item.to}
                  duration={500}
                  offset={Number(item.offset) || 0}
                  onClick={onLinkClick}
                  smooth
                >
                  {item.text}
                </Link>
              )}
            </Fragment>
          ))}
        </div>
      </div>
    </li>
  )
};

export default ProductsDropdownMobile;

import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

const SubmittedModal = props => {
  const { successMessage, opened, onClose } = props;

  const classes = cn({
    "submitted-modal": true,
    active: opened
  });

  return (
    <div className={classes}>
      <div className="submitted-modal__wrapper">
        <button className="submitted-modal__close" onClick={onClose}>
          <svg className="submitted-modal__close-icon" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="9.54608" y="7.4248" width="24" height="3" transform="rotate(45 9.54608 7.4248)" />
            <rect x="26.5167" y="9.5459" width="24" height="3" transform="rotate(135 26.5167 9.5459)" />
          </svg>
        </button>
        <div className="submitted-modal__content" dangerouslySetInnerHTML={{ __html: successMessage }}></div>
      </div>
    </div>
  );
};

SubmittedModal.propTypes = {
  opened: PropTypes.bool,
  successMessage: PropTypes.string,
};

SubmittedModal.defaultProps = {
  opened: false
};

export default SubmittedModal;

import React from 'react';
import PropTypes from 'prop-types';
import history from '../../utils/history';

import CmsDataContainer from "./CmsDataContainer"
import { DEFAULT_LANGUAGE, ALLOWED_LANGUAGES } from './constants';

const lsLang = localStorage.getItem('lang');



const LocalStorageLangResolver = props => {
  const {
    match,
  } = props;

  //console.log(props);

  const { routerLang, subpage } = match.params;

  if(!routerLang) {
    localStorage.removeItem('lang');

    if(lsLang) {
      //console.log(`redirecting by local storage to ${lsLang}`);
      history.push(`/${lsLang}`);
      return null;
    }
  } else {
    if (!ALLOWED_LANGUAGES.includes(routerLang)) {
      localStorage.setItem('lang', DEFAULT_LANGUAGE);
      history.push(`/${DEFAULT_LANGUAGE}`);
    } else {
      localStorage.setItem('lang', routerLang);
    }
  }

  return <CmsDataContainer routerLang={routerLang} subpage={subpage} />
};

LocalStorageLangResolver.propTypes = {
  routerLang: PropTypes.string,
  subpage: PropTypes.string,
  match: PropTypes.object,
};

export default LocalStorageLangResolver;

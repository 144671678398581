import React, { useState } from 'react';

const Cookies = ({onPrivacyOpen}) => {
  const [show, setShow] = useState(true);

  const closeCookies = () => {
    setShow(false);
    localStorage.setItem('show', false);
  }

  return (
    show && !localStorage.getItem('show') &&
    <div className='cookies-wrapper'>
      <div className='cookies-container'>
        <div className='cookies-close' onClick={closeCookies}/>
        <div className='cookies-img'>
          <img src="images/Cookies_head.png" alt="" style={{width: '30px'}}/>
          <img src="images/Cookies.png" alt="" style={{width: '92px'}}/>
        </div>
        <p className='cookies-text'>
          We use cookies to improve your browsing experience on our website.
          By clicking "I Agree" or continuing to use the site you consent to our use of cookies.
          For more information, please read our <a onClick={ onPrivacyOpen } href="/" className="cookies-link">Privacy Policy</a>.
        </p>
        <button className='cookies-button' onClick={closeCookies}>I Agree</button>
      </div>
    </div>
  )
}

export default Cookies;

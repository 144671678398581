import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';


const Register = props => {
  const {
    data: {
      img,
      heading,
      form,
      fields,
      submitButton,
      signInText,
      signInButton
    },
    opened,
    onLoginOpen,
    onClose
  } = props;

  const classes = cn({
    modal: true,
    'js-reg-modal': true,
    active: opened
  });

  return (
    <div className={ classes }>
      <a className='modal-logo' href='/'>
        <img src={ img.src } alt={ img.alt } />
      </a>
      <span
        className='close-modal'
        onClick={ onClose }
        role='presentation'
      />
      <form
        className='modalForm'
        action={ form.action }
        method={ form.method }
      >
        <div className='modalForm__wrap'>
          <span className='modalForm__title modalForm__title_margin'>
            { heading }
          </span>
          { fields.map(item => (
            <div key={ item.label }>
              <label className='modalForm__label'>
                { item.label }
              </label>
              <input
                className='modalForm__input'
                type={ item.type }
                name={ item.name }
                placeholder={ item.placeholder }
              />
            </div>
          )) }
          <button className='modalForm__btn' type={ submitButton.type }>
            { submitButton.text }
          </button>
        </div>
        <div className='modalForm__footer'>
          <p className='modalForm__info'>
            { signInText }
            &nbsp;
            <a
              className='modalForm__link'
              href={ signInButton.link }
              onClick={ onLoginOpen }
            >
              { signInButton.text }
            </a>
          </p>
        </div>
      </form>
    </div>
  );
};

Register.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string,
    img: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string
    }),
    form: PropTypes.shape({
      action: PropTypes.string,
      method: PropTypes.string
    }),
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        type: PropTypes.string,
        name: PropTypes.string,
        placeholder: PropTypes.string
      })
    ),
    submitButton: PropTypes.shape({
      type: PropTypes.string,
      text: PropTypes.string
    }),
    signInText: PropTypes.string,
    signInButton: PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.string
    })
  }),
  opened: PropTypes.bool,
  onLoginOpen: PropTypes.func
};

Register.defaultProps = {
  data: {},
  opened: false
};

export default Register;
